import Resful from "@/services/resful.js";

export default {
    data: function() {
        return {
            email: "",
            success: false
        };
    },
    methods: {
        forgotPassword: async function() {
            if (!this.email) {
                $("#forgot-pwd-component .warning").css("display", "flex");
                return;
            }
            let query = { email: this.email };
            let { data } = await Resful.post("/auth/forgot-password", JSON.stringify(query));
            if (data.message) {
                this.$swal({
                    title: data.message,
                    type: "error",
                    showConfirmButton: false,
                    timer: 2500
                });
                return;
            }
            this.success = true;
            this.$swal({
                title: "Success!",
                type: "success",
                showConfirmButton: false,
                timer: 2500
            });
        }
    },
    mounted() {
        $("#forgot-pwd-component input").on("focus", function() {
            $("#forgot-pwd-component .warning").hide();
        });
    }
};
